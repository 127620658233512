var columns = [{
  title: '内部货号',
  dataIndex: 'sku',
  key: 'sku',
  // width: '7%',
  scopedSlots: {
    customRender: 'sku'
  }
}, {
  title: '外部货号',
  dataIndex: 'outsku',
  key: 'outsku',
  // width: '7%',
  scopedSlots: {
    customRender: 'outsku'
  }
}, {
  title: '商品图片',
  dataIndex: 'imgurls',
  key: 'imgurls',
  // width: '10%',
  scopedSlots: {
    customRender: 'imgurls'
  }
}, {
  title: '尺码',
  dataIndex: 'size',
  key: 'size',
  // width: '10%',
  scopedSlots: {
    customRender: 'size'
  }
}, {
  title: '最新报价($)',
  dataIndex: 'price',
  key: 'price',
  // width: '10%',
  scopedSlots: {
    customRender: 'price'
  }
}, {
  title: '售价($)',
  dataIndex: 'minprice',
  key: 'minprice',
  // width: '10%',
  scopedSlots: {
    customRender: 'minprice'
  }
}, {
  title: '上架时间',
  dataIndex: 'updrowtime',
  key: 'uptime',
  // width: '10%',
  scopedSlots: {
    customRender: 'updrowtime'
  }
}, {
  title: '下架时间',
  dataIndex: 'downtime',
  key: 'downtime',
  // width: '10%',
  scopedSlots: {
    customRender: 'downtime'
  }
}, {
  title: '上次更新时间',
  dataIndex: 'updatetime',
  key: 'updatetime',
  // width: '10%',
  scopedSlots: {
    customRender: 'updatetime'
  }
}, {
  title: '更新频率(min)',
  dataIndex: 'rate',
  key: 'rate',
  // width: '10%',
  scopedSlots: {
    customRender: 'rate'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  // width: '10%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  title: '下架原因',
  dataIndex: 'reason',
  key: 'reason',
  // width: '10%',
  scopedSlots: {
    customRender: 'reason'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
var detailColums = [{
  title: '时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '7%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '角色',
  dataIndex: 'roletype',
  key: 'roletype',
  // width: '7%',
  scopedSlots: {
    customRender: 'roletype'
  }
}, {
  title: '用户',
  dataIndex: 'username',
  key: 'username',
  // width: '10%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '操作',
  dataIndex: 'operationtype',
  key: 'operationtype',
  // width: '10%',
  scopedSlots: {
    customRender: 'operationtype'
  }
}, {
  title: '详情',
  dataIndex: 'memo',
  key: 'memo',
  // width: '10%',
  scopedSlots: {
    customRender: 'memo'
  }
}];
export { columns, detailColums };