var searchKeys = [{
  key: "sku",
  label: "内部货号",
  placeholder: "请输入内部货号",
  required: false,
  rules: [],
  input: true
}, {
  key: "outsku",
  label: "外部货号",
  placeholder: "请输入外部货号",
  required: false,
  rules: [],
  input: true
}, {
  key: "minprice",
  label: "最低售价",
  placeholder: "请输入最低售价",
  required: false,
  rules: [
    // {
    //     type: 'number',
    //     required: false,
    //     message: '请输入正确售价!'
    // }
  ],
  inputNumber: true,
  conutmax: 99999999999999
}, {
  key: "maxprice",
  label: "最高售价",
  placeholder: "请输入最高售价",
  required: false,
  rules: [],
  inputNumber: true,
  conutmax: 99999999999999
}, {
  key: 'dates',
  label: '上架时间',
  format: "YYYY-MM-DD HH:mm:ss",
  placeholder: ["开始时间", "截止时间"],
  dates: true,
  rules: [{
    type: 'array',
    required: false,
    message: '请选择时间段!'
  }]
}, {
  key: "rate",
  label: "更新频率",
  placeholder: "请选择",
  required: false,
  rules: [],
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };